<script lang="ts">
    import { Writable } from 'svelte/store';
    import { fly } from 'svelte/transition';

    import Modal from '$saga/components/Modal.svelte';
    import Button from '$saga/components/Button.svelte';
    import CrossIcon from '$saga/icons/CrossIcon.svelte';
    import {
        DefaultOptionList,
        MultiSelectFilterValues,
    } from '$saga/types/search.types';
    import { flattenSportsFilter } from '$saga/utils/searchFilters';
    import ArrowLeftIcon from '$saga/icons/ArrowLeftIcon.svelte';
    import {
        findCurrentSelectedFilterLabel,
        initializeLabelValues,
        resetLabels,
        resetValues,
    } from '$saga/views/List/Search/utils';

    import { FilterFormLabels, FilterFormValues, FilterType } from '../types';
    import FiltersSelection from './FiltersSelection.svelte';
    import MultiSelectFilter from './Filters/MultiSelectFilter.svelte';
    import PaxFilter from './Filters/PaxFilter.svelte';
    import SelectFilter from './Filters/SelectFilter.svelte';
    import CalendarFilter from './Filters/CalendarFilter.svelte';
    import SliderFilter from './Filters/SliderFilter.svelte';

    export let defaultOptions: DefaultOptionList;
    export let formValues: FilterFormValues;
    export let isModalOpen: Writable<boolean>;

    let formLabels: FilterFormLabels = initializeLabelValues(
        formValues,
        defaultOptions,
    );
    let selectedFilterType = FilterType.SELECTION;
    let menuTitle = '';

    /* HELPERS */
    // take the selected ids and list their corresponding label
    const formatMultiSelectFilterSelectedValues = (
        key: 'levels' | 'tags' | 'types',
    ) => {
        return formValues[key].reduce((acc, currentValue) => {
            return {
                ...acc,
                [currentValue]: {
                    value: currentValue,
                    label: findCurrentSelectedFilterLabel(
                        currentValue,
                        defaultOptions[key],
                    ),
                },
            };
        }, {});
    };

    /* ACTIONS */
    const handleSelectedFilter = (filterType: FilterType) => {
        selectedFilterType = filterType;
        switch (filterType) {
            case FilterType.DESTINATION:
                menuTitle = 'Sélectionner une destination';
                break;
            case FilterType.SPORT:
                menuTitle = 'Sélectionner un sport';
                break;
            case FilterType.PAX:
                menuTitle = 'Sélectionner un nombre de voyageurs';
                break;
            case FilterType.STARTDATE:
                menuTitle = 'Sélectionner une date de départ';
                break;
            case FilterType.LEVELS:
                menuTitle = 'Niveau sportif';
                break;
            case FilterType.TYPES:
                menuTitle = 'Type de séjour';
                break;
            case FilterType.DURATION:
                menuTitle = 'Durée du sejour';
                break;
            case FilterType.BUDGET:
                menuTitle = 'Budget par personne';
                break;
            case FilterType.TAGS:
                menuTitle = 'Thème';
                break;
        }
    };

    const handleMultiSelectValidation = (
        key: 'levels' | 'tags' | 'types',
        values: MultiSelectFilterValues,
    ) => {
        formLabels[key] = Object.keys(values)
            .map((val) =>
                findCurrentSelectedFilterLabel(val, defaultOptions[key]),
            )
            .toString();
        formValues[key] = Object.keys(values);
        resetView();
    };

    const resetView = () => {
        selectedFilterType = FilterType.SELECTION;
        menuTitle = '';
    };

    const handleResetValues = () => {
        formLabels = resetLabels();
        formValues = resetValues();
    };
</script>

<Modal bind:isOpen={isModalOpen}>
    <div slot="header" class="item-center flex justify-end">
        {#if selectedFilterType !== FilterType.SELECTION}
            <button on:click={() => resetView()}
                ><span class="size-xl text-content-neutral">
                    <ArrowLeftIcon />
                </span>
            </button>
        {/if}
        <div class="m-auto flex font-semibold text-content-neutral">
            {menuTitle}
        </div>
        {#if selectedFilterType === FilterType.SELECTION}
            <button on:click={() => ($isModalOpen = false)}>
                <CrossIcon class="size-xl fill-content-neutral" />
            </button>
        {/if}
    </div>

    {#if selectedFilterType === FilterType.SELECTION}
        <div
            class="h-full w-full bg-container-quiet"
            transition:fly={{ y: 200, duration: 200 }}
        >
            <FiltersSelection {handleSelectedFilter} {formLabels} />
            <div
                class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
            >
                <Button
                    type="button"
                    intent="link"
                    size="none"
                    class="text-sm"
                    on:click={handleResetValues}>Tout effacer</Button
                >
                <Button
                    type="submit"
                    form="travel-search-engine-form"
                    size="small"
                    shape="rounded"
                >
                    Rechercher
                </Button>
            </div>
        </div>
    {:else}
        <div class="h-full w-full" transition:fly={{ y: 200, duration: 200 }}>
            {#if selectedFilterType === FilterType.SPORT}
                <SelectFilter
                    options={flattenSportsFilter(defaultOptions.sports)}
                    handleValidateSelection={(selection) => {
                        formValues.theme = selection?.value ?? '';
                        formLabels.theme = selection?.label ?? '';
                        if (selection != null) {
                            resetView();
                        }
                    }}
                    selectedValue={formValues.theme}
                    selectedLabel={formLabels.theme}
                    placeholder={'Rechercher un sport'}
                />
            {/if}

            {#if selectedFilterType === FilterType.DESTINATION}
                <SelectFilter
                    options={defaultOptions.destinations.sort((a, b) =>
                        a.label.localeCompare(b.label),
                    )}
                    handleValidateSelection={(selection) => {
                        formValues.destination = selection?.value ?? '';
                        formLabels.destination = selection?.label ?? '';
                        if (selection != null) {
                            resetView();
                        }
                    }}
                    selectedValue={formValues.destination}
                    selectedLabel={formLabels.destination}
                    placeholder={'Rechercher une destination'}
                />
            {/if}
            {#if selectedFilterType === FilterType.PAX}
                <PaxFilter
                    childrenValue={formValues.children}
                    adultsValue={formValues.adults}
                    handleValidateSelection={(values) => {
                        formValues.children = values.childrenValue;
                        formValues.adults = values.adultsValue;
                        formLabels.nbPax = `${
                            Number(values.adultsValue) +
                            Number(values.childrenValue)
                        }`;
                        resetView();
                    }}
                />
            {/if}

            {#if selectedFilterType === FilterType.STARTDATE}
                <CalendarFilter
                    startDateValue={formValues.startDate}
                    flexibility={formValues.flexibility}
                    handleValidateSelection={(value) => {
                        formValues.startDate = value.startDate;
                        formLabels.startDate = value.startDate;
                        formValues.flexibility = value.flexibility;
                        resetView();
                    }}
                />
            {/if}

            <!-- Secondary Filters -->

            {#if selectedFilterType === FilterType.LEVELS}
                <MultiSelectFilter
                    hasSearch={false}
                    options={defaultOptions.levels}
                    selectedValues={formatMultiSelectFilterSelectedValues(
                        'levels',
                    )}
                    handleValidateSelection={(values) =>
                        handleMultiSelectValidation('levels', values)}
                />
            {/if}

            {#if selectedFilterType === FilterType.TYPES}
                <MultiSelectFilter
                    hasSearch={false}
                    options={defaultOptions.types}
                    selectedValues={formatMultiSelectFilterSelectedValues(
                        'types',
                    )}
                    handleValidateSelection={(values) =>
                        handleMultiSelectValidation('types', values)}
                />
            {/if}

            {#if selectedFilterType === FilterType.DURATION}
                <SliderFilter
                    defaultMin={defaultOptions.durationMin}
                    defaultMax={defaultOptions.durationMax}
                    label={'j'}
                    valueMin={formValues.durationMin}
                    valueMax={formValues.durationMax}
                    handleValidateSelection={(value) => {
                        formValues.durationMin = value.min;
                        formValues.durationMax = value.max;
                        formLabels.duration = `${value.min} - ${value.max}`;
                        resetView();
                    }}
                />
            {/if}

            {#if selectedFilterType === FilterType.BUDGET}
                <SliderFilter
                    defaultMin={defaultOptions.budgetMin}
                    defaultMax={defaultOptions.budgetMax}
                    valueMin={formValues.budgetMin}
                    valueMax={formValues.budgetMax}
                    label={'€'}
                    handleValidateSelection={(value) => {
                        formValues.budgetMin = value.min;
                        formValues.budgetMax = value.max;
                        formLabels.budget = `${value.min} - ${value.max}`;
                        resetView();
                    }}
                />
            {/if}

            {#if selectedFilterType === FilterType.TAGS}
                <MultiSelectFilter
                    hasSearch={false}
                    options={defaultOptions.tags}
                    selectedValues={formatMultiSelectFilterSelectedValues(
                        'tags',
                    )}
                    handleValidateSelection={(values) =>
                        handleMultiSelectValidation('tags', values)}
                />
            {/if}
        </div>
    {/if}
</Modal>
