<script lang="ts">
    import {
        DefaultOptions,
        FilteredValues,
        MultiSelectFilterValues,
    } from '$saga/types/search.types';
    import CheckboxElement from '$saga/views/List/Search/common/CheckboxElement.svelte';
    import Button from '$saga/components/Button.svelte';
    import cn from '$common/utils/cn';
    import CloseCircle from '$saga/icons/CloseCircle.svelte';
    import { filterOptions } from '$saga/utils/searchFilters';

    export let options: DefaultOptions;
    export let handleValidateSelection: (values: typeof selectedValues) => void;
    export let selectedValues: MultiSelectFilterValues = {};
    export let hasSearch = true;

    let filteredOptions = options;
    let inputSearchValue: string = '';

    const handleFilterOptions = (query: string) => {
        filteredOptions = filterOptions({ query, defaultOptions: options });
    };

    const handleToggle = (option: FilteredValues[number]) => {
        if (selectedValues[option.value]) {
            // remove via destructuring
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [option.value]: remove, ...rest } = selectedValues;
            selectedValues = rest;
        } else {
            selectedValues = {
                ...selectedValues,
                [option.value]: {
                    ...option,
                },
            };
        }
    };

    const onInputChange = (event: Event) =>
        handleFilterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        // reset filtered options
        handleFilterOptions('');
    };
</script>

<div class="h-[calc(100vh-76px)] w-full overflow-scroll p-xs pb-6xl">
    {#if hasSearch}
        <!-- Input -->
        <div class="mb-m flex">
            <input
                type="text"
                on:input={onInputChange}
                bind:value={inputSearchValue}
            />
            <button
                type="button"
                class={cn(
                    'bg-container-quiet pr-m',
                    inputSearchValue === '' && 'hidden',
                )}
                on:click|preventDefault|stopPropagation={() => {
                    inputSearchValue = '';
                    resetInput();
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
    {/if}

    {#if filteredOptions}
        {#each filteredOptions as defaultOption}
            {#if !defaultOption.hidden}
                <CheckboxElement
                    label={defaultOption.label}
                    onToggleCheck={() => handleToggle(defaultOption)}
                    isChecked={selectedValues[defaultOption.value] != null}
                />
            {/if}
        {/each}
    {/if}
</div>
<div
    class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
>
    <Button
        type="button"
        intent="link"
        class="text-sm"
        size="none"
        on:click={() => handleValidateSelection({})}>Effacer</Button
    >
    <Button
        type="button"
        shape="rounded"
        size="small"
        on:click={() => handleValidateSelection(selectedValues)}>Valider</Button
    >
</div>

<style lang="postcss">
    input {
        appearance: unset;
        display: inline-block !important;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset !important;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit !important;
        border: unset;
        border-radius: unset;
        box-shadow: unset !important;
        transition: unset;
        @apply block w-full bg-container-quiet px-m py-xs leading-6 placeholder:text-rock-200;
    }
</style>
