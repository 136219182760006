<script lang="ts">
    import Button from '$saga/components/Button.svelte';
    import { DefaultOptionList } from '$saga/types/search.types';
    import MapPinIcon from '$saga/icons/MapPinIcon.svelte';
    import WalkIcon from '$saga/icons/WalkIcon.svelte';
    import { PaxFilterValues } from '$saga/views/HomePage/SearchFilters/PaxFilter/types';
    import PaxFilterDesktop from '$saga/components/Filters/desktop/PaxFilterDesktop.svelte';
    import CalendarFilterDesktop from '$saga/components/Filters/desktop/CalendarFilterDesktop.svelte';
    import { FilterFormValues } from '../types';
    import SelectFilter from './Filters/SelectFilter.svelte';

    export let defaultOptions: DefaultOptionList;
    export let formValues: FilterFormValues;
    export let handleFormSubmissionAnalytics: () => void;

    const handleSavePax = (values: PaxFilterValues) => {
        formValues.children = values.childrenValue;
        formValues.adults = values.adultsValue;
    };
</script>

<div class="relative m-auto hidden h-[50px] max-w-layout-max desktop:block">
    <div class="absolute top-[-75px] w-full px-5xl">
        <div class="border border-solid border-bord-quiet bg-white p-m">
            <div class="flex flex-row gap-m">
                <div class="w-full">
                    <SelectFilter
                        defaultOptions={defaultOptions.destinations.sort(
                            (a, b) => a.label.localeCompare(b.label),
                        )}
                        bind:inputValue={formValues.destination}
                        inputLabel="Destination"
                        menuTitle="Sélectionner une destination"
                        icon={MapPinIcon}
                        placeholder="Ex: France"
                        analyticsQueryKey="destination"
                    />
                </div>
                <div class="w-full">
                    <SelectFilter
                        defaultOptions={defaultOptions.sports}
                        bind:inputValue={formValues.theme}
                        inputLabel="Sport"
                        menuTitle="Sélectionner un sport"
                        icon={WalkIcon}
                        placeholder="Ex: randonnée"
                        analyticsQueryKey="sport"
                    />
                </div>

                <div class="w-full">
                    <CalendarFilterDesktop
                        bind:startDateValue={formValues.startDate}
                        bind:flexibility={formValues.flexibility}
                    />
                </div>

                <div class="w-full">
                    <PaxFilterDesktop
                        childrenAges={formValues.childrenAges}
                        childrenValue={formValues.children}
                        adultsValue={formValues.adults}
                        handleSubmitValues={handleSavePax}
                    />
                </div>

                <Button
                    size="large"
                    type="submit"
                    form="travel-search-engine-form"
                    on:click={() => handleFormSubmissionAnalytics()}
                    >Rechercher</Button
                >
            </div>
        </div>
    </div>
</div>
