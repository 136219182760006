<script lang="ts">
    import dayjs from 'dayjs';
    import { writable } from 'svelte/store';
    import { createDatePicker } from '@melt-ui/svelte';
    import type { DateValue } from '@internationalized/date';
    import { parseDate } from '@internationalized/date';

    import cn from '$common/utils/cn';
    import CloseCircle from '$saga/icons/CloseCircle.svelte';
    import Button from '$saga/components/Button.svelte';
    import Modal from '$saga/components/Modal.svelte';
    import { ariaKeyDownA11yHandler } from '$common/utils/ariaKeyDownA11y';
    import CrossIcon from '$saga/icons/CrossIcon.svelte';
    import CalendarIcon from '$saga/icons/CalendarIcon.svelte';
    import FlexibilityFilter from '$saga/components/Filters/FlexibilityFilter.svelte';
    import CalendarPicker from '$saga/components/CalendarPicker.svelte';

    export let startDateValue: DateValue | undefined;
    export let flexibility = 0;

    let isModalOpen = writable(false);

    const {
        elements: {
            calendar,
            cell,
            grid,
            heading,
            nextButton,
            prevButton,
            content,
        },
        states: { value, months, headingValue, weekdays },
        helpers: { isDateDisabled, isDateUnavailable },
    } = createDatePicker({
        locale: 'fr',
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        open: isModalOpen,
    });

    const openModal = () => {
        $isModalOpen = true;
    };
</script>

<div>
    <div
        on:click={openModal}
        {...ariaKeyDownA11yHandler(openModal)}
        role="button"
        tabindex="0"
        class="flex justify-between gap-1 bg-container-quiet px-m py-xs"
    >
        <span class="flex w-full font-semibold leading-6 text-content-neutral">
            <span class="mr-xs flex items-center">
                <CalendarIcon class="size-m" />
            </span>
            Départ
        </span>
        <div class="flex w-full items-center">
            <!--  nbsp trick to keep div height if value is undefined-->
            <div
                class="w-full whitespace-nowrap text-right leading-6 text-content-neutral"
            >
                {#if startDateValue}
                    {dayjs(startDateValue.toString()).format('D MMM YYYY')}
                {:else}
                    <span class="text-rock-200">
                        {dayjs().format('D MMM YYYY')}
                    </span>
                {/if}
            </div>
            <button
                type="button"
                class={cn('ml-xs', startDateValue == null && 'hidden')}
                on:click|preventDefault|stopPropagation={() => {
                    startDateValue = undefined;
                    value.set(undefined);
                }}
            >
                <CloseCircle class="size-m fill-content-quiet" />
            </button>
        </div>
    </div>

    <Modal bind:isOpen={isModalOpen}>
        <div slot="header" class="item-center flex justify-between">
            <div class="m-auto flex font-semibold text-content-neutral">
                Sélectionner une date de départ
            </div>
            <button on:click={() => ($isModalOpen = false)}>
                <CrossIcon class="size-xl fill-content-neutral" />
            </button>
        </div>
        <div
            {...content}
            use:content
            class="fixed h-[calc(100vh-76px)] w-full overflow-scroll pb-6xl"
        >
            <CalendarPicker
                class="mx-auto px-2xl py-m"
                {calendar}
                {prevButton}
                {heading}
                {headingValue}
                {nextButton}
                {months}
                {grid}
                {weekdays}
                {isDateDisabled}
                {isDateUnavailable}
                {cell}
                isMultipleMonths={false}
            />

            <!-- Flexibility -->
            <FlexibilityFilter bind:flexibility />

            <!--  Footer validate button -->
            <div
                class="fixed bottom-0 left-0 flex w-full justify-center border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
            >
                <Button
                    class="w-full"
                    type="button"
                    on:click={() => {
                        startDateValue = $value;
                        $isModalOpen = false;
                    }}>Valider</Button
                >
            </div>
        </div>
    </Modal>
</div>
