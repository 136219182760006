<script lang="ts">
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import type { DateValue } from '@internationalized/date';
    import { parseDate } from '@internationalized/date';

    import cn from '$common/utils/cn';
    import CloseCircle from '$saga/icons/CloseCircle.svelte';
    import Button from '$saga/components/Button.svelte';
    import CalendarIcon from '$saga/icons/CalendarIcon.svelte';
    import CalendarPicker from '$saga/components/CalendarPicker.svelte';
    import FlexibilityFilter from '$saga/components/Filters/FlexibilityFilter.svelte';
    import { defaultFormValues } from '$saga/views/List/Search/constants';

    export let startDateValue: DateValue | undefined;
    export let flexibility = defaultFormValues.flexibility;

    let calendarTriggerBtn: HTMLElement;

    const {
        elements: {
            trigger,
            calendar,
            cell,
            grid,
            heading,
            nextButton,
            prevButton,
            content,
        },
        states: { value, months, headingValue, open, weekdays },
        helpers: { isDateDisabled, isDateUnavailable },
    } = createDatePicker({
        locale: 'fr',
        numberOfMonths: 2,
        forceVisible: true,
        positioning: {
            placement: 'bottom-start',
        },
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        defaultValue: startDateValue,
    });
</script>

<div
    bind:this={calendarTriggerBtn}
    {...$trigger}
    use:trigger
    role="button"
    tabindex="0"
    class="flex w-auto flex-col gap-0.5 bg-container-quiet px-m py-xs hover:bg-secondary-regular-container-hover"
>
    <span class="flex font-semibold leading-6 text-content-neutral">
        <span class="mr-xs flex items-center">
            <CalendarIcon class="size-m" />
        </span>
        Départ
    </span>
    <div class="flex w-full items-center">
        <!--  nbsp trick to keep div height if value is undefined-->
        <div class="w-full leading-6 text-content-neutral">
            {#if startDateValue}
                {dayjs(startDateValue.toString()).format('D MMM YYYY')}
            {:else}
                <span class="text-rock-200">
                    Ex: {dayjs().format('D MMM YYYY')}
                </span>
            {/if}
        </div>
        <button
            type="button"
            class={cn('ml-xs', startDateValue == null && 'hidden')}
            on:click|preventDefault|stopPropagation={() => {
                startDateValue = undefined;
                value.set(undefined);
            }}
        >
            <CloseCircle class="size-m fill-content-quiet" />
        </button>
    </div>
</div>

{#if $open}
    <div {...$content} use:content>
        <div
            class="my-m flex justify-center font-semibold text-content-neutral"
        >
            Sélectionner une date de départ
        </div>

        <CalendarPicker
            class="px-2xl py-m"
            {calendar}
            {prevButton}
            {heading}
            {headingValue}
            {nextButton}
            {months}
            {grid}
            {weekdays}
            {isDateDisabled}
            {isDateUnavailable}
            {cell}
            isMultipleMonths={true}
        />
        <!-- Flexibility -->
        <FlexibilityFilter bind:flexibility />

        <!--  Validate button -->
        <div
            class="border-t border-solid border-commercial-neutral px-2xl py-m"
        >
            <Button
                class="w-full hover:bg-blue-brand-hover"
                type="button"
                on:click={() => {
                    startDateValue = $value;
                    calendarTriggerBtn?.click();
                }}>Valider</Button
            >
        </div>
    </div>
{/if}

<style lang="postcss">
    [data-melt-popover-content] {
        @apply z-50 border border-solid border-bord-quiet bg-white;
    }
</style>
