<script lang="ts">
    import SearchBar from '$saga/views/List/Search/SearchBar.svelte';
    import {DefaultOptionList, DefaultOptions} from '$saga/types/search.types';
    import { flattenSportsFilter } from '$saga/utils/searchFilters';

    export let defaultOptions: string;
    let defaultSportOptions: DefaultOptions = [];
    let defaultDestinationOptions: DefaultOptions = [];
    let parsedOptions: DefaultOptionList;

    $: {
        parsedOptions = JSON.parse(defaultOptions);
        defaultDestinationOptions = parsedOptions?.destinations.sort((a, b) =>
            a.label.localeCompare(b.label),
        );

        // Flatten all children, not displaying parent sports
        defaultSportOptions = flattenSportsFilter(parsedOptions?.sports);
    }
    export let searchForm: never;
</script>

<SearchBar
        defaultOptions={parsedOptions}
        defaultDestinationOptions="{defaultDestinationOptions}"
        defaultSportOptions="{defaultSportOptions}"
        {searchForm}
/>
