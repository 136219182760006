<script lang="ts">
    import { createSelect } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';
    import { ComponentType } from 'svelte';

    import { DefaultOptions, FilteredValues } from '$saga/types/search.types';
    import cn from '$common/utils/cn';
    import CloseCircle from '$saga/icons/CloseCircle.svelte';
    import { findCurrentSelectedFilterLabel } from '$saga/views/List/Search/utils';

    export let inputLabel: string;
    export let menuTitle: string | undefined;
    export let filteredOptions: FilteredValues | DefaultOptions | undefined;
    export let defaultOptions: DefaultOptions;
    export let onInputChange: (event: Event) => void;
    export let resetInput: () => void;
    export let inputValue;
    export let icon: ComponentType;
    export let placeholder: string;

    let inputRef: HTMLInputElement;
    const {
        elements: { trigger, menu, option, group, groupLabel, label },
        states: { selectedLabel, selected, open },
    } = createSelect({
        forceVisible: true,
        positioning: {
            placement: 'bottom-start',
        },
        preventScroll: false,
        onSelectedChange: ({ next }) => {
            inputValue = next?.value;
            return next;
        },
        defaultSelected: {
            value: inputValue,
            label: findCurrentSelectedFilterLabel(inputValue, defaultOptions),
        },
    });
</script>

<div
    class="flex w-auto flex-col justify-normal gap-0.5 bg-container-quiet px-m py-xs hover:bg-secondary-regular-container-hover"
    {...$trigger}
    use:trigger
    role="button"
    tabindex="0"
    on:click={() => inputRef?.focus()}
>
    <span
        class="flex font-semibold leading-6 text-content-neutral"
        {...$label}
        use:label
    >
        <span class="mr-xs flex items-center">
            <svelte:component this={icon} class="size-m" />
        </span>
        {inputLabel}
    </span>
    <div class="flex w-full items-center">
        <input
            bind:this={inputRef}
            type="text"
            {placeholder}
            value={$selectedLabel}
            on:input={onInputChange}
        />

        <button
            type="button"
            class={cn('ml-xs', $selectedLabel === '' && 'hidden')}
            on:click={() => {
                selected.set(undefined);
                resetInput();
            }}
        >
            <CloseCircle class="size-m text-content-quiet" />
        </button>
    </div>
</div>

{#if $open}
    {#if filteredOptions && filteredOptions.length > 0}
        <div
            class="z-40 flex max-h-[400px] flex-col overflow-y-auto border border-solid border-bord-quiet bg-white p-2xl"
            {...$menu}
            use:menu
            transition:fade={{ duration: 150 }}
        >
            {#if menuTitle}
                <div class="mb-xs font-semibold leading-6 text-content-neutral">
                    {menuTitle}
                </div>
            {/if}
            {#each filteredOptions as filteredOption}
                {#if !filteredOption?.hidden}
                    <div {...$group(filteredOption.value)} use:group>
                        <div
                            class={cn(
                                'cursor-pointer p-m leading-6 text-content-neutral',
                            )}
                            {...$groupLabel(filteredOption.label)}
                            use:groupLabel
                            use:option
                            {...$option({
                                value: filteredOption.value,
                                label: filteredOption.label,
                            })}
                        >
                            {filteredOption.label}
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
{/if}

<style lang="postcss">
    [data-melt-select-option][data-highlighted] {
        @apply bg-commercial-neutral;
    }

    [data-melt-select-group-label][data-highlighted] {
        @apply bg-commercial-neutral;
    }

    input {
        appearance: unset;
        display: inline-block !important;
        margin: unset;
        padding: unset;
        max-width: unset;
        min-height: unset;
        outline: unset !important;
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit !important;
        border: unset;
        border-radius: unset;
        box-shadow: unset !important;
        transition: unset;
        @apply block w-full text-left leading-6 text-content-neutral placeholder:text-rock-200 focus:placeholder:text-transparent;
    }
</style>
