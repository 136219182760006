<script lang="ts">
    import dayjs from 'dayjs';
    import { DateValue } from '@internationalized/date';

    import CalendarFilterMobile from './mobile/CalendarFilterMobile.svelte';
    import CalendarFilterDesktop from '$saga/components/Filters/desktop/CalendarFilterDesktop.svelte';

    let startDateValue: DateValue | undefined;
    let flexibility: number;
</script>

<div class="hidden w-full desktop:block">
    <CalendarFilterDesktop bind:startDateValue bind:flexibility />
</div>

<div class="desktop:hidden">
    <CalendarFilterMobile bind:startDateValue bind:flexibility />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="startDate"
    value={startDateValue
        ? dayjs(startDateValue.toString()).format('DD/MM/YYYY')
        : ''}
/>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="flexibility"
    value={flexibility}
/>
