<script lang="ts">
    import NumberInput from '$saga/components/NumberInput.svelte';
    import { PaxFilterValues } from '$saga/views/HomePage/SearchFilters/PaxFilter/types';

    export let adultsValue: string;
    export let childrenValue: string;
    export let childrenAges: string[];
    export let handleSubmit: (values: PaxFilterValues) => void;

    // TODO dissociate nbPax in children and adults
    // 1 to 17 years old
    // const childAgeList = Array.from(Array(17)).map((v, index) => index + 1);
</script>

<form
    autocomplete="off"
    on:submit|preventDefault|stopPropagation={() => {
        handleSubmit({
            childrenValue,
            adultsValue,
            childrenAges,
        });
    }}
>
    <div class="flex justify-between py-m">
        <div class="flex flex-col">
            <span class="font-semibold text-content-neutral">Adultes</span>
            <span class="text-sm text-content-quiet">18 ans et plus</span>
        </div>
        <NumberInput
            bind:value={adultsValue}
            min={0}
            onIncrement={() => {
                adultsValue = `${Number(adultsValue) + 1}`;
            }}
            onDecrement={() => {
                if (Number(adultsValue) > 0)
                    adultsValue = `${Number(adultsValue) - 1}`;
            }}
            minusBtnClasses={Number(adultsValue) === 0 ? 'opacity-[0.38]' : ''}
            minusBtnProps={{ disabled: Number(adultsValue) === 0 }}
        />
    </div>

    <div
        class="flex justify-between border-t border-solid border-container-quiet py-m"
    >
        <div class="flex flex-col">
            <span class="font-semibold text-content-neutral">Enfant</span>
            <span class="text-sm text-content-quiet">Moins de 18 ans</span>
        </div>
        <NumberInput
            bind:value={childrenValue}
            min={0}
            onIncrement={() => {
                childrenValue = `${Number(childrenValue) + 1}`;
                // reactive value, do not use .push()
                childrenAges = [...childrenAges, ''];
            }}
            onDecrement={() => {
                if (Number(childrenValue) > 0) {
                    childrenValue = `${Number(childrenValue) - 1}`;
                    // mutate by removing last value
                    childrenAges = [...childrenAges.slice(0, -1)];
                }
            }}
            minusBtnClasses={Number(childrenValue) === 0
                ? 'opacity-[0.38]'
                : ''}
            minusBtnProps={{ disabled: Number(childrenValue) === 0 }}
        />
    </div>

    <!-- TODO dissociate nbPax in children and adults -->
    <!--{#if Number(childrenValue) > 0}-->
    <!--    <div>-->
    <!--        <p class="mb-xs text-content-quiet">-->
    <!--            Afin de vous proposer des séjours adaptés, nous avons besoin de-->
    <!--            connaîter l’âge qu’auront les enfants à la fin du séjour.-->
    <!--        </p>-->

    <!--        {#each childrenAges as _, index}-->
    <!--            <div class="mb-xl">-->
    <!--                <select-->
    <!--                    class="min-h-xl w-full px-m py-xs"-->
    <!--                    required-->
    <!--                    bind:value={childrenAges[index]}-->
    <!--                >-->
    <!--                    <option value="">Âge enfant {index + 1} </option>-->
    <!--                    {#each childAgeList as childrenAge}-->
    <!--                        <option value={childrenAge}>-->
    <!--                            {childrenAge}-->
    <!--                        </option>-->
    <!--                    {/each}-->
    <!--                </select>-->
    <!--            </div>-->
    <!--        {/each}-->
    <!--    </div>-->
    <!--{/if}-->

    <slot name="footer" />
</form>
