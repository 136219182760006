<script lang="ts">
    import { tick } from 'svelte';
    import dayjs from 'dayjs';

    import { findCurrentSelectedFilterLabel } from '$saga/views/List/Search/utils';
    import FilterBadge from '$saga/views/List/Search/desktop/Filters/FilterBadge.svelte';
    import { DefaultOptionList } from '$saga/types/search.types';
    import {
        FilterFormValues,
        SearchForm,
    } from '$saga/views/List/Search/types';
    import { defaultFormValues } from '$saga/views/List/Search/constants';

    export let searchForm: SearchForm;
    export let defaultOptions: DefaultOptionList;
    export let formValues: FilterFormValues;

    /**
     * When a filter change value, auto submit form
     * (after user close popover)
     */
    const handleFormAutoSubmit = async () => {
        // wait for svelte to update value on the DOM
        await tick();
        const form = document.getElementById(
            'travel-search-engine-form',
        ) as HTMLFormElement;
        if (form) form.submit();
    };
</script>

<div class="mx-auto hidden max-w-layout-max gap-xs px-5xl desktop:flex">
    {#if searchForm.destinations && searchForm.destinations.length > 0}
        {#each searchForm.destinations as dest}
            {#if dest !== '' && dest != null}
                <FilterBadge
                    label={findCurrentSelectedFilterLabel(
                        String(dest),
                        defaultOptions['destinations'],
                    )}
                    handleRemoveBadgeFilter={() => {
                        formValues.destination = defaultFormValues.destination;
                        handleFormAutoSubmit();
                    }}
                />
            {/if}
        {/each}
    {/if}

    {#if searchForm.sports}
        {#each searchForm.sports as sport}
            {#if sport !== '' && sport != null}
                <FilterBadge
                    label={findCurrentSelectedFilterLabel(
                        String(sport),
                        defaultOptions['sports'],
                    )}
                    handleRemoveBadgeFilter={() => {
                        formValues.theme = defaultFormValues.theme;
                        handleFormAutoSubmit();
                    }}
                />
            {/if}
        {/each}
    {/if}

    {#if searchForm.nbPax && searchForm.nbPax !== 1}
        <FilterBadge
            label={`${searchForm.nbPax} voyageurs`}
            handleRemoveBadgeFilter={() => {
                formValues.adults = defaultFormValues.adults;
                formValues.children = defaultFormValues.children;
                handleFormAutoSubmit();
            }}
        />
    {/if}

    {#if searchForm.startDate}
        <FilterBadge
            label={`${dayjs(searchForm.startDate.date).format(
                'DD MMM YYYY',
            )} (±${searchForm.flexibility}j)`}
            handleRemoveBadgeFilter={() => {
                formValues.startDate = defaultFormValues.startDate;
                formValues.flexibility = defaultFormValues.flexibility;
                handleFormAutoSubmit();
            }}
        />
    {/if}

    {#each searchForm.levels as level}
        <FilterBadge
            label={findCurrentSelectedFilterLabel(
                String(level),
                defaultOptions['levels'],
            )}
            handleRemoveBadgeFilter={() => {
                formValues.levels = formValues.levels.filter(
                    (l) => l !== level,
                );
                handleFormAutoSubmit();
            }}
        />
    {/each}

    {#each searchForm.types as type}
        <FilterBadge
            label={findCurrentSelectedFilterLabel(
                String(type),
                defaultOptions['types'],
            )}
            handleRemoveBadgeFilter={() => {
                formValues.types = formValues.types.filter((t) => t !== type);
                handleFormAutoSubmit();
            }}
        />
    {/each}

    {#each searchForm.tags as tag}
        <FilterBadge
            label={findCurrentSelectedFilterLabel(
                String(tag),
                defaultOptions['tags'],
            )}
            handleRemoveBadgeFilter={() => {
                formValues.tags = formValues.tags.filter((t) => t !== tag);
                handleFormAutoSubmit();
            }}
        />
    {/each}

    {#if (searchForm.budgetMin || searchForm.budgetMax) && (searchForm.budgetMin !== defaultFormValues.budgetMin || searchForm.budgetMax !== defaultFormValues.budgetMax)}
        <FilterBadge
            label={`Entre ${searchForm.budgetMin} et ${searchForm.budgetMax} €`}
            handleRemoveBadgeFilter={() => {
                formValues.budgetMax = defaultFormValues.budgetMax;
                formValues.budgetMin = defaultFormValues.budgetMin;
                handleFormAutoSubmit();
            }}
        />
    {/if}

    {#if (searchForm.durationMin || searchForm.durationMax) && (searchForm.durationMin !== defaultFormValues.durationMin || searchForm.durationMax !== defaultFormValues.durationMax)}
        <FilterBadge
            label={`Entre ${searchForm.durationMin} et ${searchForm.durationMax} jours`}
            handleRemoveBadgeFilter={() => {
                formValues.durationMin = defaultFormValues.durationMin;
                formValues.durationMax = defaultFormValues.durationMax;
                handleFormAutoSubmit();
            }}
        />
    {/if}
</div>
