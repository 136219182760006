import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['obfuscator'];

    static values = {
        url: String,
        target: String,
    };

    click() {
        window.open(this.urlValue, this.targetValue);
    }
}
