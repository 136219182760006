<script lang="ts">
    import SearchBar from '$saga/views/HomePage/SearchBar.svelte';
    import {
        DefaultOptionList,
        DefaultOptions,
    } from '$saga/types/search.types';
    import { flattenSportsFilter } from '$saga/utils/searchFilters';

    export let defaultOptions: string;
    export let searchUrl: string;

    let defaultSportOptions: DefaultOptions = [];
    let defaultDestinationOptions: DefaultOptions = [];

    $: {
        try {
            const parsedOptions: DefaultOptionList = JSON.parse(defaultOptions);

            defaultDestinationOptions = parsedOptions?.destinations.sort((a, b) =>
                a.label.localeCompare(b.label),
            );
            // Flatten all children, not displaying parent sports
            defaultSportOptions = flattenSportsFilter(parsedOptions?.sports);
        } catch (error) {
            console.error('[HomeSearchController] Error parsing options:', error);
        }
    }
</script>

<SearchBar {defaultDestinationOptions} {defaultSportOptions} {searchUrl} />
