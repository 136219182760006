import { FilterFormValues } from '$saga/views/List/Search/types';

export const defaultFormValues: FilterFormValues = {
    destination: '',
    theme: '',
    startDate: undefined,
    flexibility: 0,
    adults: '0',
    children: '0',
    childrenAges: [],
    // secondary
    levels: [],
    types: [],
    durationMin: 1,
    durationMax: 40,
    budgetMin: 0,
    budgetMax: 10000,
    tags: [],
};
